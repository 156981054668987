<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        _href="https://1.envato.market/pixinvent_portfolio"
        _target="_blank"
      ><b>FerreSur</b></b-link>
      <span class="d-none d-sm-inline-block">, Derechos Reservados</span>
    </span>

    <span class="float-md-right d-none d-md-block">
      Desarrollado por <a
        href="https://marlons0707.github.io/"
        target="_blank"
      ><b>Monkode</b></a>
      <!-- <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      /> -->
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
